import React, { useState } from "react";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

import { FiInstagram } from "react-icons/fi";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import Logo from "../../images/common/logo.svg";

export default function Header() {
  const [menuActive, setMenuActive] = useState(false);

  function closeMenu() {
    setMenuActive(false);
  }

  function handleStateChange(state) {
    setMenuActive(state.isOpen);
  }

  return (
    <>
      <BrowserView>
        <header id="header">
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          ></link>

          <div className="content">
            <div className="row no-gutters">
              <div className="col-4 logo">
                <Link to="/">
                  <img src={Logo} alt="logo FS" />
                </Link>
              </div>

              <div className="col-8 d-flex align-items-center justify-content-between">
                <ul className="menu">
                  <li>
                    <Link to="/" className="">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/sobre" className="">
                      Sobre
                    </Link>
                  </li>
                  <li>
                    <Link to="/atuacao" className="">
                      Atuação
                    </Link>
                  </li>
                  <li>
                    <Link to="/venda-de-precatorios" className="">
                      Venda de Precatórios
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/blog" className="">
                      Blog e Notícias
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contato" className="">
                      Contato
                    </Link>
                  </li>
                </ul>
                <a href="https://www.instagram.com/fs_consultoria_juridica/" className="social-link">
                  <FiInstagram />
                </a>
              </div>
            </div>
          </div>
        </header>
      </BrowserView>
      <MobileView>
        <header id="header">
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css"
          ></link>
          <div className="content">
            <div className="row no-gutters">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="logo FS" />
                </Link>
              </div>

              <Menu
                isOpen={menuActive}
                onStateChange={(state) => handleStateChange(state)}
                className="menu-mobile"
                right
                width={"365px"}
              >
                <Link onClick={(e) => closeMenu()} to="/" className="cl-white">
                  Home
                </Link>
                <Link
                  onClick={(e) => closeMenu()}
                  to="/sobre"
                  className="cl-white"
                >
                  Sobre
                </Link>
                <Link
                  onClick={(e) => closeMenu()}
                  to="/atuacao"
                  className="cl-white"
                >
                  Atuação
                </Link>
                <Link
                  onClick={(e) => closeMenu()}
                  to="/venda-de-precatorios"
                  className="cl-white"
                >
                  Venda de Precatórios
                </Link>

                {/* <Link
                  onClick={(e) => closeMenu()}
                  to="/blog"
                  className="cl-white"
                >
                  Blog e Notícias
                </Link> */}
                <Link
                  onClick={(e) => closeMenu()}
                  to="/contato"
                  className="cl-white"
                >
                  Contato
                </Link>
              </Menu>
            </div>
          </div>
        </header>
      </MobileView>
    </>
  );
}
