import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Layout from "./Components/Layout/Layout";

import Index from "./Pages/Index/Index";
import VendaDePrecatorios from "./Pages/VendaDePrecatorios/Index";
import Blog from "./Pages/Blog/index";
import Contato from "./Pages/Contato/Index";
import Sobre from "./Pages/Sobre/index";
import Atuacao from "./Pages/Atuacao/index";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Layout>
          <Route path="/" exact component={Index} />
          <Route
            path="/venda-de-precatorios"
            exact
            component={VendaDePrecatorios}
          />
          <Route path="/blog" exact component={Blog} />
          <Route path="/contato" exact component={Contato} />
          <Route path="/sobre" exact component={Sobre} />
          <Route path="/atuacao" exact component={Atuacao} />
        </Layout>
      </Switch>
    </BrowserRouter>
  );
}
