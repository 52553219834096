import React from "react";
import "./Notice.scss";

import Notice1 from "../../images/noticia/notice-1.jpg";
import Notice2 from "../../images/noticia/notice-2.jpg";
import Notice3 from "../../images/noticia/notice-3.jpg";
import Notice4 from "../../images/noticia/notice-4.jpg";
import Notice5 from "../../images/noticia/notice-5.jpg";
import Notice6 from "../../images/noticia/notice-6.jpg";

export default function Notice() {
  const noticias = [
    {
      image: Notice1,
      titulo: "Acordo prevê pagamento de menos de 44% dos precatórios em 2022",
      text: "União pode ser obrigada a pagar R$ 39 dos R$ 89 bilhões que deve em precatórios. A diferença de R$ 50 bilhões ainda será negociada entre os credores e o governo federal sem previsão de data.",
      fonte: "Agência Senado",
    },
    {
      image: Notice2,
      titulo:
        "Relator cria limite para pagar precatório relativo a aposentadoria e pensão",
      text: "Senador Fernando Bezerra, relator da PEC dos precatórios, afirmou que a redação mais recente dada à matéria estabelece um subteto para o pagamento dos títulos nos próximos anos. ",
      fonte: "Jornews",
    },
    {
      image: Notice3,
      titulo: "CNJ: taxa Selic será usada para corrigir precatórios",
      text: "Plenário do Conselho Nacional de Justiça aprovou por unanimidade, alteração da Resolução CNJ n. 303/2019, que dispõe sobre a gestão dos precatórios e respectivos procedimentos operacionais no âmbito do Judiciário. ",
      fonte: "Portal TRF2",
    },
    {
      image: Notice4,
      titulo: "Governo Federal retoma precatórios não sacados",
      text: "Em 2021 o Governo pegou de volta dinheiro de precatórios não sacados e Requisições de Pequeno Valor (RPVs) depositados há mais de dois anos em contas judiciais. Para ter acesso a esse dinheiro novamente, o autor do processo deve solicitar a emissão de um novo precatório ou RPV por meio de petição judicial.",
      fonte: "CCMG Gestão de investimentos",
    },

  ];

  return (
    <div className="row">
      <div className="col-12">
        <div className="content">
          <section id="noticias">

            <div className="box text-left" id="precatorios">
              <h2 className="cl-gold f-35 nt1">
              NOTÍCIAS SOBRE PRECATÓRIOS
              </h2>
              <div className="d-flex flex-wrap justify-content-center justify-content-lg-between">
              {noticias.map((data) => (
                <div className="notice">
                  <img src={data.image} alt={data.titulo} />
                  <div className="texts">
                    <h4>{data.titulo}</h4>
                    <p>{data.text}</p>
                    <span>
                      <b>Fonte:</b> {data.fonte}
                    </span>
                  </div>
                </div>
              ))}
              </div>
            </div>

            <div className="box text-left" id="empresarial">
              <h2 className="cl-gold f-35 nt1 mt-4">
              NOTÍCIAS SOBRE EMPRESARIAL
              </h2>
              <div className="d-flex flex-wrap justify-content-center justify-content-lg-between">
              {noticias.map((data) => (
                <div className="notice">
                  <img src={data.image} alt={data.titulo} />
                  <div className="texts">
                    <h4>{data.titulo}</h4>
                    <p>{data.text}</p>
                    <span>
                      <b>Fonte:</b> {data.fonte}
                    </span>
                  </div>
                </div>
              ))}
              </div>
            </div>

            <div className="box text-left" id="familia">
              <h2 className="cl-gold f-35 nt3 mt-4">
              NOTÍCIAS SOBRE PROCESSOS DE FAMÍLIA
              </h2>
              <div className="d-flex flex-wrap justify-content-center justify-content-lg-between">
              {noticias.map((data) => (
                <div className="notice">
                  <img src={data.image} alt={data.titulo} />
                  <div className="texts">
                    <h4>{data.titulo}</h4>
                    <p>{data.text}</p>
                    <span>
                      <b>Fonte:</b> {data.fonte}
                    </span>
                  </div>
                </div>
              ))}
              </div>
            </div>


          </section>
        </div>
      </div>
    </div>
  );
}
