import React, { useRef, useState } from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Contato.scss";
import emailjs from "@emailjs/browser";
import { FiLoader } from "react-icons/fi";
import InputMask from "react-input-mask";

export default function Index() {
  const form = useRef();
  const [loading, setloading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setloading(true);

    emailjs
      .sendForm(
        "service_a58whjr",
        "template_0k3k8rd",
        form.current,
        "o4x_zHcMKOdw8fZEE"
      )
      .then(
        (result) => {
          alert("Contato enviado com sucesso!");
          form.current.reset();
          setloading(false);
        },
        (error) => {
          console.log(error.text);
          setloading(false);
        }
      );
  };

  return (
    <section id="contato">
      <section className="line-menu" />
      <div id="form-black">
        <div className="content">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="cl-gold f-42 font-weight-bold">Contato</h3>
              <p className="f-19 cl-white">
                Precisa de mais informações sobre a venda de precatórios ou
                tirar dúvidas sobre outros assuntos? Mande uma mensagem para a
                FS <br className="d-none d-lg-inline" /> Consultoria. Nossa
                equipe retornará sua mensagem o mais breve possível.{" "}
              </p>
              <div className="box-form">
                <span>*Campos obrigatórios</span>
                <form className="row" ref={form} onSubmit={sendEmail}>
                  <input
                    name="segmento"
                    type="hidden"
                    required=""
                    id="segmento"
                    value="Precatórios"
                  />
                  <label className="col-12 col-lg-6">
                    <input
                      name="nome"
                      type="text"
                      required=""
                      id="nome"
                      placeholder="Nome*"
                    />
                  </label>
                  <label className="col-12 col-lg-6">
                    <InputMask
                      mask="(99) 99999-9999"
                      name="telefone"
                      type="tel"
                      id="telefone"
                      placeholder="Telefone"
                    />
                  </label>
                  <label className="col-12 col-lg-6">
                    <InputMask
                      mask="999.999.999-99"
                      name="cpf"
                      type="text"
                      required=""
                      id="cpf"
                      placeholder="CPF*"
                    />
                  </label>
                  <label className="col-12 col-lg-6">
                    <input
                      name="email"
                      type="email"
                      required=""
                      id="email"
                      placeholder="E-mail*"
                    />
                  </label>
                  <label className="col-12">
                    <textarea
                      name="mensagem"
                      id="textarea"
                      placeholder="Mensagem"
                    ></textarea>
                  </label>
                  <label className="col-12 lb-button">
                    <button type="submit" className="bt" disabled={loading}>
                      {!loading ? "Enviar" : <FiLoader />}
                    </button>
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
