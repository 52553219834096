import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import "./Index.scss";

import PassosAnalise from "../../images/passos/analise.png";
import PassosConta from "../../images/passos/conta.png";
import PassosContrato from "../../images/passos/contrato.png";
import PassosProposta from "../../images/passos/proposta.png";

import Faq from "../../Components/Faq/Faq";
import { Link } from "react-router-dom";
import Formulario from "../../Components/Formulario/Formulario";

export default function Index() {
  return (
    <section id="home">
      <section id="banner">
        <div className="content">
          <div className="row no-gutters">
            <div className="col-12">
              <h1 className="cl-white f-30 f-wheight-700 mb-4">quem somos</h1>
              <p className="cl-white f-16 mb-4">
                Fundado em 2018, o escritório Fernandes & Sousa Gestão e
                Consultoria <br className="d-none d-lg-inline" />
                está localizado no Centro do Rio de Janeiro e atua no modelo
                Full <br className="d-none d-lg-inline" />
                Service, ou seja, modelo de atuação diversificada, abrangendo as
                áreas
                <br className="d-none d-lg-inline" /> do direito de maneira
                multidisciplinar, proporcionando a prestação de
                <br className="d-none d-lg-inline" /> diversos serviços
                jurídicos em um único lugar.
              </p>
              <h2 className="cl-gold f-16 f-wheight-700 mb-4 d-block">
                Com uma equipe de sólida formação, o escritório presta
                <br className="d-none d-lg-inline" /> serviços para clientes
                nacionais e estrangeiros, atuando
                <br className="d-none d-lg-inline" /> em vários setores da
                economia.
              </h2>
              <Link className="bt" to="/sobre">
                saiba mais
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="atuacao">
        <div className="content">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="f-25">atuação Full service </h2>
              <p>
                Procuramos atuar de forma concreta nas necessidades dos nossos
                clientes. Por isso, somos pioneiros em full service.
              </p>
            </div>
            <div className="col-12 row no-gutters d-flex flex-wrap justify-content-center justify-content-lg-between links">
              <a href="/atuacao#familia" className="lk1 col-12 col-lg-4">
                <span>familia</span>
              </a>
              <a href="/atuacao#empresarial" className="lk2 col-12 col-lg-4">
                <span>empresarial</span>
              </a>
              <a href="/atuacao#precatorios" className="lk3 col-12 col-lg-4">
                <span>precatorio</span>
              </a>
            </div>
            <div className="col-12 button-line d-flex align-center justify-content-center">
              <Link className="bt" to="/sobre">
                SAIBA MAIS
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="banner-full">
        <div className="content">
          <div className="row">
            <div className="col-12 text-left d-flex flex-column justify-content-center">
              <h2 className="f-25">Venda de Precatórios </h2>
              <p>
                Antecipe seu precatório com
                <br /> segurança e rapidez.
              </p>
              <Link className="bt" to="/venda-de-precatorios">
                VENDER AGORA
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section id="banner-mid">
        <div className="content">
          <div className="row background no-gutters">
            <div className="col-12 d-flex align-items-center justify-content-center flex-column">
              <h2 className="f-25">Confira notícias relacionadas </h2>
              <Link className="bt" to="/blog">
                VER NOTÍCIAS
              </Link>
            </div>
          </div>
        </div>
      </section>

      <Formulario />
      
    </section>
  );
}
